import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Box, Button } from 'grommet';

import { useStoryblokState } from '../lib/storyblok';
import { get } from '../lib/nodash';
import deNodify from '../lib/deNodify';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import Seo from '../components/Seo';
import SmartLink from '../components/SmartLink';
import IconArrow from '../components/Icons/IconArrow';
import useMenuOffset from '../components/useMenuOffset';

const BlogCategoryTemplate = ({
  data: { storyblokEntry, site, posts, categories },
  pageContext: { currentPage = 1, numPages = 1, full_slug },
  location,
}) => {
  const canonicalUrl = site.siteMetadata.siteUrl + location.pathname;
  const story = useStoryblokState(storyblokEntry);
  const offset = get('content.offsetForMenu', story);
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = isFirst ? undefined : currentPage - 1;
  const nextPage = isLast ? undefined : currentPage + 1;

  const blogPosts = deNodify(posts).map((x) => ({
    ...x,
    category: deNodify(categories).find((c) =>
      x.full_slug.replace(x.slug, '').includes(c.full_slug)
    ),
  }));

  const components = usePageBodyComponents(story, {
    pageTitle: story.content.title,
    pageSlug: story.full_slug,
    blogPosts,
  });
  const { navHeight } = useMenuOffset();

  return (
    <>
      <Seo
        title={`${
          get('content.metafields.title', story) ||
          get('title', story) ||
          get('field_title_string', storyblokEntry)
        }${isFirst ? '' : ` – Page ${currentPage}`}`}
        canonical={canonicalUrl}
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        noIndex={get('content.noIndex', story)}
      />
      <Box pad={{ top: offset ? `${navHeight}px` : 0 }}>{components}</Box>
      {(nextPage || prevPage) && (
        <Box
          pad="xlarge"
          direction="row-responsive"
          justify="between"
          gap="small"
          border={{ side: 'top', size: 'small', color: 'black' }}
        >
          {prevPage ? (
            <SmartLink to={`/articles/${prevPage === 1 ? '' : prevPage}`}>
              <Button
                label="Newer articles"
                icon={<IconArrow direction="left" size="small" />}
                secondary
              />
            </SmartLink>
          ) : (
            <Box />
          )}
          {nextPage ? (
            <SmartLink to={`/${full_slug}${nextPage === 1 ? '' : nextPage}`}>
              <Button
                label="Older articles"
                reverse
                icon={<IconArrow direction="right" size="small" />}
                secondary
              />
            </SmartLink>
          ) : (
            <Box />
          )}
        </Box>
      )}
    </>
  );
};

BlogCategoryTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
};

export default memo(BlogCategoryTemplate);

export const query = graphql`
  query BlogCategoryContent(
    $full_slug: String!
    $category_glob: String!
    $limit: Int!
    $skip: Int
  ) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      id
      name
      full_slug
      content
      field_title_string
      internalId
    }
    posts: allStoryblokEntry(
      filter: {
        field_component: { eq: "BlogPost" }
        full_slug: { glob: $category_glob }
      }
      sort: { order: DESC, fields: first_published_at }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          full_slug
          published_at
          first_published_at
          content
          field_title_string
          internalId
        }
      }
    }
    categories: allStoryblokEntry(
      filter: {
        field_component: { eq: "Page" }
        full_slug: { glob: "articles/*" }
      }
    ) {
      edges {
        node {
          full_slug
          slug
          published_at
          name
          internalId
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
